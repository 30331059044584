.link-box {
  padding: 15px;
  background: #F6F6FB;
  font-size: 13px;
  font-weight: 600;
  text-align: center;

  &-primary {
    color: $primary;
  }
}
