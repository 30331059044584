$backdrop-color: #fff;
$snotify-width: auto !default;

@if $snotify-width == auto {
    $snotify-width: 100%;
}

.snotify {
    display: block;
    position: fixed;
    width: $snotify-width;
    z-index: 99999;
    box-sizing: border-box;
    pointer-events: none;

    * {
        box-sizing: border-box;
    }
}

.snotify-leftTop,
.snotify-leftCenter,
.snotify-leftBottom {
    left: 10px;
}

.snotify-rightTop,
.snotify-rightCenter,
.snotify-rightBottom {
    right: 10px;
}

.snotify-centerTop,
.snotify-centerCenter,
.snotify-centerBottom {
    left: calc(50% - #{$snotify-width} / 2);
}

.snotify-leftTop,
.snotify-centerTop,
.snotify-rightTop {
    top: env(safe-area-inset-top);
}

.snotify-leftCenter,
.snotify-rightCenter,
.snotify-centerCenter {
    top: 50%;
    transform: translateY(-50%);
}

.snotify-leftBottom,
.snotify-rightBottom,
.snotify-centerBottom {
    bottom: 10px;
}

.snotify-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $backdrop-color;
    opacity: 0;
    z-index: 9998;
    transition: opacity 0.2s;;
}
