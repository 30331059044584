@media (min-width: 1080px) {
  super-tabs,
  super-tabs-container,
  super-tab {
    width: 812px;
    margin: 0 auto;
    border-radius: 15px;
  }

  ion-header.is-responsive-header {
    width: 1088px;
    margin: 24px auto;
    border-radius: 15px;

    .responsive-custom-toolbar {
      ion-toolbar {
        border-radius: 10px;
        border: none;
        --background: #fff;
        background: #fff;
      }
    }
  }

  ion-content.is-responsive-content {
    right: -138px;
  }

  super-tabs-toolbar {
    display: none;
  }

  .responsive-content {
    width: 812px;
    max-width: 100%;
    margin: 0 auto;
    background: #fff;
    border-radius: 10px;
    padding: 10px 15px !important;
  }

  .responsive-background {
    --background: #f3f3f3 !important;
  }

  .responsive-toolbar {
    max-width: 810px;
    margin: 20px auto;
    border: none !important;
    border-radius: 10px;
    --background: #fff !important;
  }

  .responsive-header {
    background: #f3f3f3;

    &.header-md {
      &:after {
        content: none;
      }
    }
  }

  ion-footer.responsive-footer {
    padding: 0px;
    border: none;
    background: #f3f3f3;

    ion-toolbar {
      background: transparent;
      --background: transparent;
      margin: 0 auto;
      max-width: 810px;
      padding: 0px;
    }
  }

  body {
    background: #f3f3f3 !important;
  }

  .responsive-filters {
    width: 812px;
    margin: 0px auto 20px;
    background: #fff;
    border-radius: 10px;

    .filter-bar {
      border-radius: 10px;
    }
  }
}

.desktop-menu {
  position: absolute;
  left: 0;
  top: 90px;
  width: 260px;
  background: #fff;
  padding: 10px 0;
  border-radius: 16px;
  box-shadow: 0 10px 20px rgb(27 38 94 / 3%);

  &-item {
    display: flex;
    align-items: center;
    padding: 8px 24px;
    cursor: pointer;

    .icon {
      display: flex;
      margin-right: 13px;
      width: 19px;
      min-width: 19px;
    }

    .name {
      font-size: 13px;
      color: #111720;
    }

    &.is-active {
      .icon {
        svg {
          path {
            fill: #F65909;
          }
        }
      }
    }
  }

  &-no-icon {
    .desktop-menu-item {
      &.is-active {
        .name {
          color: #F65909;
        }
      }
    }
  }
}

@media (max-width: 1079px) {
  .desktop-menu {
    display: none;
  }
}
