.modal-with-padding {
  --width: calc(100% - 30px);
  --height: calc(100% - 30px);
  --border-radius: 8px;
}


.modal {
  &-header {
    border-bottom: 1px solid $separator-color;
    padding: 15px 20px;
    background: $body-background;
    position: relative;
  }

  &-dismiss {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    font-size: 19px;
    color: $text-primary;
    background: #F6F6FB;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
  }

  &-title {
    color: $primary;
    font-weight: 600;
    text-align: center;
    line-height: 25px;
    font-size: 13px;
    padding: 0 40px;
  }

  &-body {
    padding: 16px;
    color: $text-primary;
    line-height: 20px;
  }

  &-footer {
    padding: 16px 24px;
  }
}
