.dot {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: $main;
  border: 2px solid #12171f;
  position: absolute;

  &-notifications, &-messages {
    top: 13px;
    right: 0;
  }
}
