@import 'font-face';
@import 'variables';
@import 'plugins/snotify/material';
@import 'links';
@import 'form';
@import 'modal';
@import 'button';
@import 'checkbox';
@import 'list';
@import 'link-box';
@import 'dot';
@import 'error';
@import 'avatar';
@import 'tabs';
@import 'action-sheet';
@import 'main-nav';
@import 'show-more';
@import 'simple-table';
@import 'nw-calendar';
@import 'leaflet';
@import 'ng-select';
@import 'grid';
@import 'mobile';
@import 'quill';

* {
  user-select: initial;
  font-family: inherit;
}

body {
  background: $body-background;
  font-family: $font-family;

  &.quick-menu-open {
    app-quick-menu {
      opacity: 1;
      visibility: visible;

      &:after {
        opacity: 0.3;
      }
      .quick-menu {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  &.main-menu-open {
    app-main-menu {
      opacity: 1;
      visibility: visible;

      &:after {
        opacity: 0.3;
      }

      .main-menu {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}

.loading-wrapper.sc-ion-loading-md {
  box-shadow: none !important;
  background: transparent;
}

.submit-trigger {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
  left: -999999px;
}

#bulb {
  width: 100%;
  height: 180px;
  background: #12171F;
  position: absolute;
  top: calc(0 + env(safe-area-inset-top));
  left: 0;
  z-index: -1;
  border-bottom-left-radius: 80px;
  border-bottom-right-radius: 80px;
}

:host(.unbounded) {
  contain: strict !important;
}

.container {
  padding: 0 15px;
}

.swiper-slide {
  cursor: pointer;
}

.overview-tasks {
  .swiper-container {
    overflow: visible;
  }
  .pagination {
    position: absolute;
    z-index: 11;
    left: 50%;
    transform: translateX(-50%);
    bottom: -25px;
  }
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background: #BACBD9;
    border-radius: 100%;
    opacity: 1;
    margin: 0 2px;

    &-active {
      background: #F65909;
    }
  }
}

.default-footer {
  padding: 15px;
  background: #fff;
  border-top: 1px solid #EDEBF6;

  ion-button {
    margin: 0;
    --box-shadow: none;
  }

  &:before {
    content: none;
  }
}

ion-searchbar {
  --background: #F6F6FB !important;
  --box-shadow: none !important;
  --border-radius: 5px !important;
  --icon-color: #4E4E4E !important;
  padding: 0 !important;
  height: 38px !important;

  .searchbar-input-container {
    height: 100% !important;

    input {
      height: 38px !important;
      color: #110A48 !important;
      padding-inline-end: 20px !important;
      font-size: 13px !important;
      padding-inline-start: 50px !important;
    }

    ion-icon {
      width: 17px !important;
      height: 17px !important;
    }
  }

  .searchbar-clear-icon {
    svg {
      path {
        fill: #7D8FB3 !important;
      }
    }
  }
}

.leaflet-control-attribution {
  display: none !important;
}

.page-widget {
  position: fixed;
  right: 10px;
  bottom: 15px;
  z-index: 401;
}

app-default-card {
  ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 15px;
    --inner-padding-start: 15px;
    --detail-icon-color: #4E4E4E;
    --detail-icon-opacity: 1;
    --detail-icon-font-size: 17px;
    --ripple-color: #9e9e9e;
    --background-activated: #9e9e9e;
    --background-focused: #9e9e9e;
    --min-height: 32px;
  }

  &.is-modal {
    .default-card-header {
      border: none;
    }
    .default-card-content,
    .incoming-tasks {
      padding-top: 0;
    }
  }
}

.contacts,
.sent-files {
  app-accordion {
    .accordion {
      &-content {
        padding: 0;
      }
    }
  }
}

.content-text {
  font-size: 12px;
  color: #4E4E4E;
  line-height: 22px;
}

.user-realizations-photos {
  ion-slides {
    .swiper-pagination {
      bottom: 0;
    }
    .swiper-pagination-bullet {
      opacity: 0.8;
    }
  }
}

.dropzone {
  ngx-dropzone-remove-badge {
    opacity: 1 !important;
  }
}

ion-spinner.loader {
  --color: #f65909;
  width: 100%;
  height: 40px;
}

.action-sheet-cancel {
  box-shadow: 0 10px 20px rgba(27, 38, 94, 0.03);
}

.form-group-set {
  ion-button {
    color: #111720;
    background: #ececec;
    border-radius: 5px;
    margin-top: 10px;

    span {
      font-weight: 600;
      display: inline-block;
      margin-left: 4px;
    }
  }
}

app-chat {
  ion-infinite-scroll-content {
    min-height: 40px !important;

    .infinite-loading {
      margin-bottom: 0 !important;
    }
  }
}

ion-toggle {
  --ion-color-primary: #f65909;
  --ion-text-color-rgb: #f65909;
  --background-checked: rgb(246 89 9 / 25%);
  --background: rgb(0 0 0 / 39%);
}

ng-select[formControlName="assigned"] {
  .ng-value {
    display: flex !important;
    align-items: center;
  }
}

ng-select[ng-reflect-is-disabled="true"] {
  pointer-events: none;

  .ng-select-container {
    background: #ededed;
  }
}

@media (min-width: 1024px) {

  $scrollbar-2-thumb-width: 3px;
  $scrollbar-2-thumb-color: rgb(212, 212, 212);
  $scrollbar-2-track-color: rgb(230, 230, 230);

  * {
    // Chrome & Opera
    &::-webkit-scrollbar {
      width: 0;
      height: $scrollbar-2-thumb-width !important;

        &-track {
            background-color: $scrollbar-2-track-color !important;
        }

        &-thumb {
          background-color: $scrollbar-2-thumb-color !important;
        }

      &-track,
      &-thumb {
        border-radius: 4px !important;
      }
    }
  }
}

.wall-post-popover,
.wall-post-gallery-popover {
  --width: calc(100vw - 24px);
  --max-width: 1440px;
  --max-height: calc(100vh - 24px);

  ion-backdrop {
    opacity: .5 !important;
  }

  .popover-content {
    position: static;

    img {
      display: block;
      width: 100%;
    }
  }
}

.wall-post-popover {
  --max-width: 800px;
}

.filter-bar {

  &.dark-mode {

    .search {
      ion-searchbar {

        .searchbar-input-container .searchbar-input {
          color: #F5F5F5 !important;
        }
      }
    }
  }
}


app-dashboard-events {
  .event-calendar {
    .schedule-calendar-body-header {
      background: #111720;
      border-top: 1px solid #111720;
      border-bottom: 1px solid #111720;

      .schedule-calendar-day {
        color: #FFFFFF;
      }
    }

    .schedule-calendar-body {
      background: #111720;
    }

    .schedule-calendar-number {
      color: #B2B2B2;
    }
  }

  .event-calendar-change-trigger-bg {
    background: #111720 !important;
  }

  .tasks {
    background: #FFFFFF !important;
  }

  #dashboard-event-calendar-change-trigger {
    background: #FFFFFF !important;
    color: #12171F !important;
    &:after {
      background: #111720 !important;
    }
  }

  .event-item {
    background: #fff !important;
    border: 1px solid #ECECEC;

    &-title {
      .text {
        color: #2f3846 !important;
      }
    }
  }

  .no-tasks {
    background: #fff !important;
    border: 1px solid #ECECEC;

    span {
      color: #2f3846 !important;
    }
  }

  .event-calendar-body {
    background: #111720 !important;
  }

  .event-calendar-item {
    &.is-today {
      background: #2f3846 !important;
    }

    .event-calendar-number,
    .event-calendar-day {
      color: #FFFFFF !important;
    }
  }

  .selected-filters {
    background: #12171f !important;
    padding-top: 5px;
  }
}

app-investment-events {
  .schedule-calendar-item.is-today:after {
    opacity: 1 !important;
  }
}


app-wall-post app-comments .comments {
  max-height: 200px;
  overflow-y: auto;
  padding-right: 17px;
  margin-right: -17px;
}

.lb-nav a.lb-prev,
.lb-nav a.lb-next {
  opacity: 0.5 !important;
}
