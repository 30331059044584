
.form {
  &-group {
    position: relative;
    margin-bottom: 10px;

    ion-grid {
      padding: 0;
      margin-left: -5px;
      margin-right: -5px;
    }
    ion-col {
      padding-top: 0;
      padding-bottom: 0;

      .form-control {
        padding-left: 12px;
      }
    }

    &-md {
      margin-bottom: 16px;
    }

    &-note {
      .error-message {
        padding-left: 0;
      }
    }

    &-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #EDEBF6;
      margin: 0;
      padding: 5px 0 5px 10px;

      &-name {
        font-size: 14px;
        color: #111720;
      }

      &.has-description {
        border: none;

        .form-group-toggle-name {
          span {
            display: block;
            font-size: 10px;
            line-height: 10px;
          }
        }
      }
    }
  }

  &-label {
    width: 100%;
    padding: 16px;
    font-size: inherit;
    font-size: 13px;
    color: $text-primary;
    border: none;
    position: absolute;
    top: 0;
    left: 1px;
    display: block;
    transition: all .1s ease-in-out;
  }

  &-group-select-trigger {
    padding: 0 16px;
    height: 46px;
    font-size: 13px;
    line-height: 46px;
    color: #111720;
    font-weight: 500;
    border: 1px solid #ECECEC;
    margin: 0;
    border-radius: 8px;
    outline: 0;
    position: relative;

    ion-ripple-effect {
      border-radius: 8px;
    }

    &-placeholder {
      width: 100%;
      padding: 0 16px;
      font-size: inherit;
      font-size: 13px;
      color: #4E4E4E;
      border: none;
      position: absolute;
      font-weight: 400;
      top: 0;
      left: 0;
      display: block;
      transition: all 0.1s ease-in-out;
    }

    &-arrow {
      position: absolute;
      top: 0;
      right: 0;
      height: 46px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #4E4E4E;
      font-size: 16px;
      background: transparent;
    }

    &.has-value {
      .form-group-select-trigger-placeholder {
        font-size: 11px;
        top: -8px;
      }
      .form-group-select-trigger-value {
        padding-top: 6px;
      }
    }

    &.is-disabled {
      background: #ededed;
      pointer-events: none;
    }
  }

  &-control {
    width: 100%;
    padding: 0 16px;
    height: 46px;
    font-size: 13px;
    line-height: 46px;
    color: $primary;
    font-weight: 500;
    border: 1px solid $input-border-color;
    margin: 0;
    border-radius: 8px;
    outline: 0;
    background: #fff;

    &:focus {
      outline: 0;
    }

    &.is-disabled,
    &:disabled {
      background: #ededed;
      pointer-events: none;

      + label {
        pointer-events: none;
      }
    }

    &::placeholder {
      color: transparent;
    }

    &:not(:placeholder-shown) {
      padding-top: 19px;
      padding-bottom: 5px;
    }

    &:not(:placeholder-shown) ~ label {
      padding: 8px 16px;
      font-size: 11px;
    }

    &.has-error {
      border-color: $danger;
    }

    &-note {
      border: none;
      padding-top: 0 !important;
      background: #fff;
      background-attachment: local;
      background-image:
        linear-gradient(to right, white 0px, transparent 0px),
        linear-gradient(to left, white 0px, transparent 0px),
        repeating-linear-gradient(white, white 30px, #F6F6FB 30px, #F6F6FB 31px, white 31px);
      line-height: 31px !important;

      ~ .form-label {
        display: none !important;
      }

      &::placeholder {
        color: #4E4E4E;
      }

      &-input {
        height: 31px;
        font-size: 15px;
      }

      &-textarea {
        font-size: 12px;
      }
    }

    &-textarea {
      min-height: 120px;
      resize: none;
      line-height: 20px;
      padding-top: 30px;

      &:not(:placeholder-shown) ~ label {
        top: 1px;
        padding-top: 18px;
        background: #fff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: calc(100% - 2px);
      }

      &:not(:placeholder-shown) {
        padding-top: 40px;
      }

      &-md {
        min-height: 85px;
      }
    }

    &-chat {
      background: #F5F7FB;
      border: none;
      color: #4E4E4E;
      border-radius: 30px;
    }
  }
}

textarea.form-control-note {
  min-height: calc(100vh - 240px);
  resize: none;
  line-height: 18px;
  padding-top: 14px;

  &:not(:placeholder-shown) {
    padding-top: 20px;
  }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
