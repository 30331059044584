ngx-avatar {
  position: relative;
  display: block;
  &:after {
    content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    width: 38px;
    height: 38px;
    background: transparent;
    border: 6px solid rgba(255, 255, 255, 0.75);
    border-radius: 100%;
  }

  .avatar-content {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 100%;
  }

  &.square {
    .avatar-content {
      border-radius: 25px !important;
    }

    &:after {
      content: '' !important;
      border: 4px solid #fff;
      border-radius: 25px;
      width: 102px;
      height: 102px;
    }
  }

  &.size-xs {
    &:after {
      width: 30px;
      height: 30px;
      border-width: 3px;
    }
  }

  &.size-xxs {
    &:after {
      width: 20px;
      height: 20px;
      border-width: 3px;
      border-color: #343149;
    }
  }

  &.size-sm {
    &:after {
      width: 54px;
      height: 54px;
    }
  }

  &.has-thumb {
    &:after {
      content:none;
    }
  }

  &.no-border {
    &:after {
      content: none;
    }
  }
}
