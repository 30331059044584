.leaflet-container {
  font-family: $font-family !important;
}

.leaflet-popup {
  margin-bottom: 40px !important;
  border-radius: 5px !important;

  &-content {
    margin: 0 !important;

    &-wrapper {
      box-shadow: 0 10px 30px rgba(27, 38, 94, 0.07) !important;
    }
  }
}

.investment-popup {
  display: flex;
  padding: 8px 16px 8px 8px;
  cursor: pointer;
  color: #494E55;

  &-image {
    min-width: 60px;
    height: 60px;
    margin: -16px 0 0 -16px;
    margin-right: 10px;
    border-radius: 50%;

    &.no-image {
      display: none;
    }
  }

  &-name {
    font-size: 12px;
    font-weight: 600;
  }

  &-profession {
    font-size: 12px;
    font-weight: 500;
    margin-top: 6px;
    text-transform: uppercase;
  }

  &-rating {
    display: block;
    margin-top: -6px;
  }
}
