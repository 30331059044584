
.simple-table {
  min-width: 800px;
  border-collapse: separate;
  border-spacing: 0 2px;
  width: 100%;

  th {
    background: #fff;
    padding: 10px 15px;
    text-align: left;
    font-size: 11px;
    font-weight: 500;
    color: #4E4E4E;
    text-transform: uppercase;

    &:first-child {
      padding-left: 15px;
    }
  }

  td {
    padding: 18px 15px;
    white-space: nowrap;
    text-align: left;
    font-size: 13px;
    color: #111720;

    &:first-child {
      padding-left: 15px;
      font-weight: 600;
      white-space: normal;
    }

    &.pesimistic {
      background: #FFF2F2;
      border-left: 2px solid #FF7878;
    }

    &.optimistic {
      background: #F2FFF2;
      border-left: 2px solid #96E186;
    }

    &.real {
      background: #F2F8FF;
      border-left: 2px solid #7FB6FF;
    }
  }

  tr:nth-child(odd) {
    background: #FBFBFD;
  }
}
