$simple-bg: #fff;
$simple-color: #000;
$simple-progressBar: #c7c7c7;
$simple-progressBarPercentage: #4c4c4c;

$success-bg: #4caf50;
$success-color: #c8e6c9;
$success-progressBar: #388e3c;
$success-progressBarPercentage: #81c784;

$info-bg: #1e88e5;
$info-color: #e3f2fd;
$info-progressBar: #1565c0;
$info-progressBarPercentage: #64b5f6;

$warning-bg: #ff9800;
$warning-color: #fff3e0;
$warning-progressBar: #ef6c00;
$warning-progressBarPercentage: #ffcc80;

$error-bg: #f44336;
$error-color: #ffebee;
$error-progressBar: #c62828;
$error-progressBarPercentage: #ef9a9a;

$async-bg: $info-bg;
$async-color: $info-color;
$async-progressBar: $info-progressBar;
$async-progressBarPercentage: $info-progressBarPercentage;

$confirm-bg: #009688;
$confirm-color: #e0f2f1;
$confirm-progressBar: #4db6ac;
$confirm-progressBarPercentage: #80cbc4;

$prompt-bg: #009688;
$prompt-color: #e0f2f1;

$snotify-title-font-size: auto !default;
$snotify-body-font-size: auto !default;

@if $snotify-title-font-size == auto {
    $snotify-title-font-size: 1.8em;
}

@if $snotify-body-font-size == auto {
    $snotify-body-font-size: 1em;
}

.snotifyToast {
    display: block;
    cursor: pointer;
    background-color: $simple-bg;
    height: 100%;
    margin: 15px;
    opacity: 0;
    border-radius: 0px;
    pointer-events: auto;
    margin-bottom: 20px;
    box-shadow: 0 10px 30px rgb(27 38 94 / 7%);

    &--in {
        animation-name: appear;
    }

    &--out {
        animation-name: disappear;
    }

    &__inner {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        padding: 0;
        min-height: 50px;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.5);

        .snotify-icon {
            display: none !important;
        }
    }

    &__progressBar {
        position: relative;
        width: 100%;
        height: 6px;
        background-color: $simple-progressBar;

        &__percentage {
            position: absolute;
            top: 0;
            left: 0;
            height: 6px;
            background-color: $simple-progressBarPercentage;
            max-width: 100%;
        }
    }

    &__title {
        font-size: $snotify-title-font-size;
        line-height: 1.2em;
        margin-bottom: 5px;
        color: #fff;
    }

    &__body {
        font-size: $snotify-body-font-size;
    }
}

.snotifyToast-show {
    transform: translate(0, 0);
    opacity: 1;
}

.snotifyToast-remove {
    max-height: 0;
    overflow: hidden;
    transform: translate(0, 50%);
    opacity: 0;
}

.fadeOutRight {
    animation-name: fadeOutRight;
}

/***************
 ** Modifiers **
 **************/

.snotify-simple {
    .snotifyToast__title,
    .snotifyToast__body {
        color: $simple-color;
    }
}

.snotify-success {
    background-color: #F5F5F5;
    border: none;
    border-radius: 10px;

    .snotifyToast__progressBar {
        background-color: $success-progressBar;
    }

    .snotifyToast__progressBar__percentage {
        background-color: $success-progressBarPercentage;
    }

    .snotifyToast__body {
        color: #fff;
    }
}

.snotify-info {
  background-color: #F5F5F5;
  border: none;
  border-radius: 10px;

    .snotifyToast__progressBar {
        background-color: $info-progressBar;
    }

    .snotifyToast__progressBar__percentage {
        background-color: $info-progressBarPercentage;
    }

    .snotifyToast__body {
        color: $info-color;
    }
}

.snotify-warning {
  background-color: #F5F5F5;
  border: none;
  border-radius: 10px;

    .snotifyToast__progressBar {
        background-color: $warning-progressBar;
    }

    .snotifyToast__progressBar__percentage {
        background-color: $warning-progressBarPercentage;
    }

    .snotifyToast__body {
        color: $warning-color;
    }
}

.snotify-error {
  background-color: #F5F5F5;
  border: none;
  border-radius: 10px;

    .snotifyToast__progressBar {
        background-color: $warning-progressBar;
    }

    .snotifyToast__progressBar__percentage {
        background-color: red;
    }

    .snotifyToast__body {
        color: $error-color;
    }
}

.snotify-async {
    background-color: $async-bg;

    .snotifyToast__progressBar {
        background-color: $async-progressBar;
    }

    .snotifyToast__progressBar__percentage {
        background-color: $async-progressBarPercentage;
    }

    .snotifyToast__body {
        color: $async-color;
    }
}

.snotify-confirm {
    background-color: $confirm-bg;

    .snotifyToast__progressBar {
        background-color: $confirm-progressBar;
    }

    .snotifyToast__progressBar__percentage {
        background-color: $confirm-progressBarPercentage;
    }

    .snotifyToast__body {
        color: $confirm-color;
    }
}

.snotify-prompt {
    background-color: $prompt-bg;

    ng-snotify-prompt {
        width: 100%;
    }

    .snotifyToast__title {
        margin-bottom: 0;
    }

    .snotifyToast__body {
        color: $prompt-color;
    }
}

.snotify-confirm,
.snotify-prompt {
    .snotifyToast__inner {
        padding: 10px 15px;
    }
}

.notification {
    display: flex;
    position: relative;
    width: 100%;

    &-icon {
      margin-right: 15px;
      width: 35px;

      align-items: center;

      min-width: 35px;
      min-height: 50px;
      display: flex;
      justify-content: center;

      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &-close {
        position: absolute;
        z-index: 1;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #303030;
        font-size: 14px;
        right: -10px;
    }

    &-body {
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        padding: 10px 0;
        color: #111720;
        width: 100%;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &-success {
        .notification-icon {
            background: #D6FBE7;
            ion-icon {
              --color: #2DD47A;
            }
        }
    }

    &-error {
      .notification-icon {
        background: #FFDDDD;
        ion-icon {
          --color: #FF5454;
        }
      }
    }

    &-info {
      .notification-icon {
        background: #D5EAFF;
        ion-icon {
          --color: #3198FF;
        }
      }
    }

    &-warning {
      .notification-icon {
        background: #D5EAFF;
        ion-icon {
          --color: #3198FF;
        }
      }
    }
}
