.show-more {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4E4E4E;
  padding: 16px 10px;
  border-top: 1px solid #EDEBF6;
  transition: 0.2s;
  cursor: pointer;

  ion-icon {
    font-size: 19px;
    margin-right: 8px;
  }

  span {
    font-size: 11px;
  }

  &:hover {
    background: #EDEBF6;
  }
}
