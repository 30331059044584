$box-shadow-default: 0 10px 30px rgba(27, 38, 94, 0.07);

$primary: #110A48;

$text-primary: #4E4E4E;

$separator-color: #EDEBF6;

$body-background: #FBFBFD;

$font-family: 'Avalon';

$primary-gradient: linear-gradient(90deg, rgba(246,89,9,1) 0%, rgba(254,68,112,1) 100%);

$input-border-color: #ECECEC;

$main: #f65909;

$danger: #ff3131;
$success: #53cc37;
