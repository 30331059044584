.anchor {
  color: $primary;
  text-decoration: underline;
  font-size: 13px;
  font-weight: 500;
}

.anchor-text {
  text-decoration: none;
  color: $text-primary;
  font-size: 13px;
  font-weight: 400;
}
