.main-nav {
  margin: 8px 0;

  &-item {
    width: 100%;
    padding: 8px 15px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }

  &-body {
    display: flex;
    align-items: center;
  }

  &-name {
    color: #111720;
    font-size: 13px;
    line-height: 13px;
  }

  &-action {
    color: #4E4E4E;
    display: flex;
  }

  &-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F6F6FB;
    border-radius: 100%;
    margin-right: 16px;
    color:#4E4E4E;
  }
}
