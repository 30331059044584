.default-tabs {
  &-item {
    color: #4E4E4E;
    font-size: 11px;
    line-height: 12px;
    padding: 15px 0;
    transition: color 0.2s;

    &:after {
      transition: 0.2s;
      opacity: 0;
      visibility: hidden;
      content: '';
      position: absolute;
      bottom: 0;
      height: 3px;
      width: 100%;
      background: linear-gradient(90deg, rgba(246,89,9,1) 0%, rgba(254,68,112,1) 100%);
      left: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &.is-active {
      color: #F65909;

      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
