@font-face {
  font-family: 'Avalon';
  src: url('../fonts/avalon/Avalon-Book.eot');
  src: local('Avalon Book'), local('Avalon-Book'),
      url('../fonts/avalon/Avalon-Book.eot?#iefix') format('embedded-opentype'),
      url('../fonts/avalon/Avalon-Book.woff2') format('woff2'),
      url('../fonts/avalon/Avalon-Book.woff') format('woff'),
      url('../fonts/avalon/Avalon-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Avalon';
  src: url('../fonts/avalon/Avalon-BookOblique.eot');
  src: local('Avalon Book Oblique'), local('Avalon-BookOblique'),
  url('../fonts/avalon/Avalon-BookOblique.eot?#iefix') format('embedded-opentype'),
  url('../fonts/avalon/Avalon-BookOblique.woff2') format('woff2'),
  url('../fonts/avalon/Avalon-BookOblique.woff') format('woff'),
  url('../fonts/avalon/Avalon-BookOblique.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Avalon';
  src: url('../fonts/avalon/Avalon-Medium.eot');
  src: local('Avalon Medium'), local('Avalon-Medium'),
      url('../fonts/avalon/Avalon-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/avalon/Avalon-Medium.woff2') format('woff2'),
      url('../fonts/avalon/Avalon-Medium.woff') format('woff'),
      url('../fonts/avalon/Avalon-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avalon';
  src: url('../fonts/avalon/Avalon-MediumOblique.eot');
  src: local('Avalon Medium Oblique'), local('Avalon-MediumOblique'),
  url('../fonts/avalon/Avalon-MediumOblique.eot?#iefix') format('embedded-opentype'),
  url('../fonts/avalon/Avalon-MediumOblique.woff2') format('woff2'),
  url('../fonts/avalon/Avalon-MediumOblique.woff') format('woff'),
  url('../fonts/avalon/Avalon-MediumOblique.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Avalon';
  src: url('../fonts/avalon/Avalon-Demi.eot');
  src: local('Avalon Demi'), local('Avalon-Demi'),
      url('../fonts/avalon/Avalon-Demi.eot?#iefix') format('embedded-opentype'),
      url('../fonts/avalon/Avalon-Demi.woff2') format('woff2'),
      url('../fonts/avalon/Avalon-Demi.woff') format('woff'),
      url('../fonts/avalon/Avalon-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avalon';
  src: url('../fonts/avalon/Avalon-DemiOblique.eot');
  src: local('Avalon Demi Oblique'), local('Avalon-DemiOblique'),
  url('../fonts/avalon/Avalon-DemiOblique.eot?#iefix') format('embedded-opentype'),
  url('../fonts/avalon/Avalon-DemiOblique.woff2') format('woff2'),
  url('../fonts/avalon/Avalon-DemiOblique.woff') format('woff'),
  url('../fonts/avalon/Avalon-DemiOblique.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Avalon';
  src: url('../fonts/avalon/Avalon-Bold.eot');
  src: local('Avalon Bold'), local('Avalon-Bold'),
      url('../fonts/avalon/Avalon-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/avalon/Avalon-Bold.woff2') format('woff2'),
      url('../fonts/avalon/Avalon-Bold.woff') format('woff'),
      url('../fonts/avalon/Avalon-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
