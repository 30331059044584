audio,
canvas,
progress,
video {
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

b,
strong {
  font-weight: bold;
}

img {
  max-width: 100%;
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  height: 1px;
  border-width: 0;
  box-sizing: content-box;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

label,
input,
select,
textarea {
  font-family: inherit;
  line-height: normal;
}

textarea {
  overflow: auto;
  height: auto;
  font: inherit;
  color: inherit;
}

textarea::placeholder {
  padding-left: 2px;
}

form,
input,
optgroup,
select {
  margin: 0;
  font: inherit;
  color: inherit;
}

html input[type=button],
input[type=reset],
input[type=submit] {
  cursor: pointer;
  -webkit-appearance: button;
}

a,
a div,
a span,
a ion-icon,
a ion-label,
button,
button div,
button span,
button ion-icon,
button ion-label,
.ion-tappable,
[tappable],
[tappable] div,
[tappable] span,
[tappable] ion-icon,
[tappable] ion-label,
input,
textarea {
  touch-action: manipulation;
}

a ion-label,
button ion-label {
  pointer-events: none;
}

button {
  border: 0;
  border-radius: 0;
  font-family: inherit;
  font-style: inherit;
  font-variant: inherit;
  line-height: 1;
  text-transform: none;
  cursor: pointer;
  -webkit-appearance: button;
}

[tappable] {
  cursor: pointer;
}

a[disabled],
button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input[type=checkbox],
input[type=radio] {
  padding: 0;
  box-sizing: border-box;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

