.file-action-sheet,
.default-action-sheet {
  .action-sheet-button-inner {
    &:after {
      content: url(../img/chevron-right.svg);
      position: absolute;
      right: 0;
    }
  }

  .action-sheet-button-inner.sc-ion-action-sheet-ios {
    justify-content: flex-start;
  }

  .action-sheet-icon.sc-ion-action-sheet-md,
  .action-sheet-button.sc-ion-action-sheet-ios .action-sheet-icon.sc-ion-action-sheet-ios {
    margin-inline-end: 15px;
    padding: 0;
  }


  .action-sheet-button {
    font-size: 13px !important;
    color: #110A48 !important;

    ion-icon {
      color: #7D8FB3 !important;
    }
  }

  .action-sheet-title {
    position: relative;

    &:after {
      content: '';
      width: 48px;
      height: 3px;
      background: #E1E1E1;
      border-radius: 2px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .action-sheet-title.sc-ion-action-sheet-md {
    min-height: 20px;
  }
}

.file-action-sheet {
  .action-sheet-button-inner {
    &:after {
      content: none;
    }
  }

  .action-sheet-title {

  }
}
