.form-group-checkbox {
  margin-left: 8px;
}

.form-group-label {
  display: flex;
  padding-left: 30px;

  span {
    color: $text-primary;
    font-size: 13px;
    .anchor {
      font-size: 13px;
    }
  }
  &-control {
    appearance: none;
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 22px;
    width: 22px;
    transition: all 0.15s ease-out 0s;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    display: inline-block;
    margin-right: 10px;
    outline: none;
    z-index: 1000;
    position: absolute;
    left: 0;
    top: -4px;

    &:checked {
      background: linear-gradient(180deg, rgba(246,89,9,1) 0%, rgba(254,68,112,1) 100%);
    }

    &:checked:before {
      display: inline-block;
      height: 10px;
      width: 4px;
      border-bottom: 2px solid #fff;
      border-right: 2px solid #fff;
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      opacity: 1;
    }

    &:after {
      content: "";
      width: 22px;
      height: 22px;
      background: #fff;
      border: none;
      box-shadow: inset 0px 0px 0px 1px $input-border-color;
      position: absolute;
      border-radius: 5px;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:checked:after {
      content: none;
    }
  }

}
