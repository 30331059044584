.btn {
  &-primary {
    --background: linear-gradient(90deg, rgba(246,89,9,1) 0%, rgba(254,68,112,1) 100%);
    --box-shadow: 5px 5px 15px rgba(253, 80, 86, 0.2);
    --border-radius: 8px;
    --background-activated-opacity: 0;
    --background-focused-opacity: 0;
    --background-hover-opacity: 0;
    text-transform: none;
    height: 46px;
    font-size: 14px;
    font-weight: 500;
  }

  &-danger {
    --background: linear-gradient(90deg, #dc3545 0%, #dc3545 100%);
    --box-shadow: 5px 5px 15px rgba(253, 80, 86, 0.2);
    --border-radius: 8px;
    --background-activated-opacity: 0;
    --background-focused-opacity: 0;
    --background-hover-opacity: 0;
    text-transform: none;
    height: 46px;
    font-size: 14px;
    font-weight: 500;
  }

  &-dismiss {
    --background: #F6F6FB;
    --box-shadow: none;
    --border-radius: 8px;
    --background-activated-opacity: 0;
    --background-focused-opacity: 0;
    --background-hover-opacity: 0;
    --color: #111720;
    text-transform: none;
    height: 46px;
    font-size: 14px;
    font-weight: 500;
  }

  &-outline {
    --background: #F6F6FB;
    --ion-color-primary-shade: #ccccce;
    --box-shadow: none;
    --border-radius: 8px;
    --background-activated-opacity: 0.2;
    --background-focused-opacity: 0;
    --background-hover-opacity: 0;
    --color: #111720;
    border: 1px solid #D4DEF3;
    border-radius: 8px;
    text-transform: none;
    height: 46px;
    font-size: 14px;
    font-weight: 500;

    ion-icon {
      color: #4E4E4E;
    }

    &.btn-danger {
      --background: none;
    }
  }

  &-tag {
    --background: #fff;
    --box-shadow: none;
    --border-radius: 28px;
    --background-activated-opacity: 0;
    --background-focused-opacity: 0;
    --background-hover-opacity: 0;
    --color: #111720;
    border: 1px solid #F2F2F3;
    border-radius: 28px;
    text-transform: none;
    height: 28px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    margin-left: 0;
    margin-right: 10px;

    ion-icon {
      font-size: 14px;
      margin-right: 7px;
    }
  }

  &-fab {
    --padding-start: 8px;
    --padding-end: 8px;
    --color: #848D9B;
    outline: 0;
    font-size: 17px;
    margin: 0;
    color:#848D9B;

    &.is-active {
      color:#aeabc7;
    }

    &-sm {
      height: 24px;
      width: 24px;
      --padding-start: 0;
      --padding-end: 0;
      font-size: 14px;
    }
  }

  &-chat {
    --border-radius: 100%;
    --color: #fff;
    height: 42px;
    width: 42px;
    font-size: 12px;

    &-images {
      @extend .btn-dismiss;
      @extend .btn-chat;
      --color: #111720;
    }
  }

  &-add {
    --border-radius: 100%;
    --color: #fff;
    height: 42px;
    width: 42px;
    font-size: 19px;

    ion-icon {
      color: #fff;
    }
  }

  &-square {
    --padding-start: 8px;
    --padding-end: 8px;
    margin: 0;
    margin-left: 10px;
    width: 38px;
    height: 38px;
    font-size: 14px;
    background: #F6F6FB;
    border-radius: 5px;
    color:#4E4E4E;
  }

  &-select {
    --background: #2F3846;
    --box-shadow: none;
    --border-radius: 5px;
    --background-activated-opacity: 0;
    --background-focused-opacity: 0;
    --background-hover-opacity: 0;
    --padding-start: 0;
    --padding-end: 8px;
    font-weight: 500;
    height: 28px;
    text-transform: none;
    font-size: 11px;
    margin: 0;
    color: #848D9B;

    &-title {
      padding: 0 8px;
    }

    &-dot {
      width: 4px;
      height: 4px;
      border-radius: 100%;
      margin-left: 8px;
    }

    &-icon {
      display: flex;
    }
  }

  &-xs {
    height: 20px;
    font-size: 11px;
    padding: 0 15px;
  }

  &-sm {
    height: 32px;
    font-size: 13px;
  }

  &-widget {
    --padding-start: 0;
    --padding-end: 0;
    --border-radius: 100%;
    width: 56px;
    height: 56px;
    padding: 0;
    margin: 0;
    border: 6px solid #fff;
    border-radius: 100%;
    box-shadow: 0 10px 30px rgb(27 38 94 / 7%);

    ion-icon {
      font-size: 24px;
    }
  }

  &-link {
    --background: transparent;
    --box-shadow: none;
    --border-radius: 5px;
    --background-activated: #ececec;
    --background-focused: #dddddd;
    --background-hover: #ececec;
    --ripple-color: #9e9e9e;
    margin: 0;
    text-transform: none;
    height: 20px;
    font-size: 11px;
    font-weight: 400;
    color: #f65909;
  }

  &-panel {
    --background: #F6F6FB;
    --padding-start: 15px;
    --padding-end: 15px;
    --padding-top: 15px;
    --padding-bottom: 15px;
    --background-activated: #e9e9eb;
    --background-focused: #e9e9eb;
    --background-hover: #e9e9eb;
    height: 100%;
    color: #4E4E4E;
    font-weight: 400;

    ion-icon {
      font-size: 21px;
      margin: 5px 0 8px;
    }

    div {
      color: #4E4E4E;
      font-size: 13px;

    }
  }

  &-social {
    --background: #fff;
    --padding-start: 10px;
    --padding-end: 10px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    height: 40px;
    width: 40px;
    box-shadow: 0 10px 30px rgb(27 38 94 / 7%)

    &-more {
      color: #4E4E4E;
    }
  }
}
