.list {
  &-numeric {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    font-size: 13px;
    line-height: 25px;
    color: $text-primary;
  }
}
