.nw-calendar {
  // font-family: inherit !important;
  // border: none !important;
  // min-width: unset !important;
  // width: 100%;
  // max-width: 100% !important;
  // border-radius: unset !important;

  &-header {
    display: none !important;
  }

  &-body {
    // margin: 0 !important;
    // border: none !important;
    // padding: 0 !important;
    // border-radius: 0 !important;
  }

  &-day-num {
    font-size: 13px;
    font-weight: 600;
    color: #111720;
  }

  &-day-name {
    font-size: 13px !important;
    color: #7D8FB3 !important;
  }

  .nw-day-div {
    border: 2px solid transparent;

    &.is-selected {
      border: 2px solid #F65909;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom: none;
      background: rgba(246, 89, 9, 0.05);

      .nw-calendar-day-name {
        color: #111720;
      }
    }
  }

  .nw-week-div {
    margin: 0;

    &.is-selected {
      border: 2px solid #F65909;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top: none;
      background: rgba(246, 89, 9, 0.05);
    }
  }
}

.nw-active-day {
  color: #F65909 !important;
  background: none !important;
}
